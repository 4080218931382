<template>
  <section :class="$style.subscriptions">
    <div :class="$style.wrapper">
      <div :class="$style.search">
        <el-input placeholder="Поиск" v-model="search" clearable />
        <el-button
          :class="$style.button"
          type="primary"
          icon="el-icon-search"
          @click="getList"
        >
          Поиск
        </el-button>
      </div>
      <el-select
        v-model="selectedType"
        clearable
        placeholder="Тип подписки"
        style="margin-bottom: 1rem"
        @change="getList()"
      >
        <el-option
          v-for="item in subscriptionTypesForSelect"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <el-table :data="subscriptions" stripe>
      <el-table-column prop="type" label="Тип">
        <template slot-scope="scope">
          {{ subscriptionTypes[scope.row.type] }}
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="Дата оформления">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column prop="userAccountName" label="Пользователь">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="handleUserSelected(scope.row.userAccountId)"
          >
            {{
              scope.row.userAccount.firstName +
              ' ' +
              scope.row.userAccount.lastName
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="product" label="Товар">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="handleProductSelected(scope.row.productId)"
          >
            {{ scope.row.productName }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column width="80">
        <template slot-scope="scope">
          <ActionButtons @delete="remove(scope.row)" />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </section>
</template>

<script>
import { formatDate } from '@/helpers/index'
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
export default {
  components: { ActionButtons },
  data() {
    return {
      subscriptions: [],
      subscriptionTypes: {
        sales: 'Подписка на скидку',
        preorder: 'Предзаказ',
      },
      subscriptionTypesForSelect: [
        {
          label: 'Подписка на скидку',
          value: 'sales',
        },
        {
          label: 'Предзаказ',
          value: 'preorder',
        },
      ],
      selectedType: '',
      limit: 20,
      page: 1,
      total: 10,
      search: '',
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        limit: this.limit,
        page: this.page,
        withUsers: true,
        type: this.selectedType,
        orderBy: 'created_at',
        orderDir: 'desc',
      }

      if (this.search) {
        query.search = this.search
      }
      const { value, error } =
        await delivery.AccountsCore.SubscriptionsActions.getList(query)
      loading.close()

      if (error) {
        return
      }

      this.subscriptions = value.data
      this.total = value.meta.count
    },
    handleUserSelected(userId) {
      this.$router.push('/addwine/users/' + userId)
    },
    handleProductSelected(productId) {
      this.$router.push('/addwine/catalog/products/' + productId)
    },
    async remove(data) {
      const isConfirm = confirm(
        `Вы точно хотите удалить подписку пользователя ${
          data.userAccount.firstName + ' ' + data.userAccount.lastName
        } на ${data.productName}?`,
      )
      if (isConfirm) {
        const res = await delivery.AccountsCore.SubscriptionsActions.delete(
          data.id,
        )
        if (!res.error) {
          this.getList()
        }
      }
    },
    formatDate(date) {
      return formatDate(date)
    },
  },
}
</script>

<style lang="scss" module>
.subscriptions {
  padding: 1rem;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    align-items: center;
    .button {
      margin-bottom: 1rem;
      margin-left: 0;
      margin-bottom: 0;
      border-radius: 0;
    }
    .search {
      display: flex;
      align-items: center;
      margin-right: auto;
      input {
        border-radius: 0;
      }
    }
  }
  .pagination {
    @include pagination;
  }
}
</style>
